<template>
  <v-simple-table>
    <thead>
      <tr>
        <th
          :key="key"
          :width="header.width || 'auto'"
          class="text-left"
          :class="{ 'pointer-events-none': false }"
          v-for="(header, key) in headers"
        >
          <a
            @click.prevent="handleSort(header)"
            class="header"
            :class="{ sortable: sortable(header) }"
            role="button"
            href="#"
          >
            {{ header | headerName }}

            <v-icon v-if="hasSortIcon(header)">
              {{ sort | sortIcon }}
            </v-icon>
          </a>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="(item, key) in collection"
        :key="key"
        @click="enableDetails ? $router.push(item.path) : null"
        :class="{ clickable: !loading }"
      >
        <td :key="header.property" v-for="header in headers" class="py-1 px-2">
          <StatusBadge :status="item[header.property]" v-if="header.status" />

          <v-btn
            small
            rounded
            color="primary"
            v-else-if="header.button"
            :disabled="item[header.hide_key] === header.hide_comparator"
            @click="handleAction(item[header.key], key, header)"
          >
            Tag as Sent
          </v-btn>

          <span v-else-if="header.boolean">
            {{ item[header.property] ? 'Yes' : 'No' }}
          </span>

          <span v-else>
            <span v-if="header.property == 'bank_accounts'">
              <div
                v-for="(account, key) in item[header.property]"
                :key="key"
                style="font-size: 0.8rem"
              >
                <b>Bank Name :</b> {{ account.bank_name }}<br />
                <b>Act Name :</b> {{ account.bank_account_name }} <br />
                <b>Act No. :</b> {{ account.bank_account_number }} <br />
                <b>BSB :</b> {{ account.bank_state_branch }}
              </div>
            </span>

            <span v-else-if="header.property == 'tradie_profile'">
              <div
                v-for="(account, key) in item[header.property].bank_accounts"
                :key="key"
                style="font-size: 0.8rem"
              >
                <b>Bank Name :</b> {{ account.bank_name }}<br />
                <b>Act Name :</b> {{ account.bank_account_name }} <br />
                <b>Act No. :</b> {{ account.bank_account_number }} <br />
                <b>BSB :</b> {{ account.bank_state_branch }}
              </div>
            </span>

            <span v-else-if="header.property == 'action'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    icon
                    @click="
                      handleAction(
                        item[header.key],
                        item[header.invoice_number],
                        header
                      )
                    "
                    v-on="on"
                  >
                    <v-icon>{{ icons.export }}</v-icon>
                  </v-btn>
                </template>
                <span>Export to PDF</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    icon
                    @click="$router.push(item.path)"
                    v-on="on"
                  >
                    <v-icon>{{ icons.open }}</v-icon>
                  </v-btn>
                </template>
                <span>Open Invoice</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    icon
                    @click="
                      handleAction(
                        item[header.key],
                        item[header.invoice_number],
                        { ...header, isDelete: true }
                      )
                    "
                    v-on="on"
                  >
                    <v-icon>{{ icons.delete }}</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </span>

            <template v-else>
              <span v-if="header.property === 'created_at'">
                {{ item[header.property] | formatDate('ll') }}
              </span>

              <span v-else>
                {{ item | attribute(header.property) }}
              </span>
            </template>
          </span>
        </td>
      </tr>

      <tr v-if="loading" class="pointer-events-none">
        <td
          :key="`placeholder-for=${header.property}`"
          v-for="header in headers"
          class="pl-0"
        >
          <v-skeleton-loader type="list-item" />
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import {
  mdiArrowDown,
  mdiArrowUp,
  mdiFilePdf,
  mdiArrowRightBoldHexagonOutline,
  mdiTrashCanOutline,
} from '@mdi/js'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import StatusBadge from '@/components/StatusBadge'

export default {
  data() {
    return {
      icons: {
        export: mdiFilePdf,
        open: mdiArrowRightBoldHexagonOutline,
        delete: mdiTrashCanOutline,
      },
    }
  },
  components: {
    StatusBadge,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    enableDetails: {
      type: Boolean,
      default: true,
    },
    resourcePath: String,
    resourceKey: String,
    headers: Array,
    collection: Array,
    sort: String,
    headerClicked: Function,
    actionClicked: Function,
  },

  methods: {
    sortable({ sortable = true }) {
      return sortable
    },

    hasSortIcon({ sortable = true, property = '' }) {
      return sortable && property === this.sort?.replace('-', '')
    },

    handleSort({ sortable = true, property }) {
      if (sortable) {
        this.$emit('headerClicked', property)
      }
    },

    handleAction(value, index, { clickable = true, isDelete = false }) {
      if (isDelete) {
        this.$emit('deleteAction', value, index)
        return
      }
      if (clickable) {
        this.$emit('actionClicked', value, index)
      }
    },
  },

  filters: {
    headerName: function ({ property, name = '' }) {
      return name || startCase(property)
    },

    attribute: function (item, property) {
      return item[camelCase(property) + 'Attribute'] || item[property] || '---'
    },

    sortIcon: function (sort) {
      return sort.startsWith('-') ? mdiArrowUp : mdiArrowDown
    },
  },
}
</script>
