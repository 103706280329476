<template>
  <span>
    <v-btn
      depressed
      small
      rounded
      color="error"
      v-if="status === 'NEW'"
      class="disable-events"
    >
      <strong> {{ status }} </strong>
    </v-btn>
    <v-btn
      depressed
      small
      rounded
      color="secondary"
      v-if="status === 'DRAFT'"
      class="disable-events"
    >
      <strong> {{ status }} </strong>
    </v-btn>
    <v-btn
      depressed
      small
      rounded
      color="primary"
      v-if="status === 'IN_PROGRESS'"
      class="disable-events"
    >
      <strong>IN PROGRESS</strong>
    </v-btn>
    <v-btn
      depressed
      small
      rounded
      color="success"
      v-if="status === 'COMPLETED'"
      class="disable-events"
    >
      <strong> {{ status }} </strong>
    </v-btn>
    <v-btn
      depressed
      small
      rounded
      color="success"
      v-if="status === 'SENT'"
      class="disable-events"
    >
      <strong> {{ status }} </strong>
    </v-btn>
    <v-btn
      depressed
      small
      rounded
      color="error"
      v-if="status === 'PAYMENT_ERROR'"
      class="disable-events"
    >
      <strong>ERROR</strong>
    </v-btn>
    <v-btn
      depressed
      small
      rounded
      color="primary"
      v-if="status === 'SENDING'"
      class="disable-events"
    >
      <strong>{{ status }}</strong>
    </v-btn>
    <v-btn
      depressed
      small
      rounded
      color="primary"
      v-if="status === 'RECEIVED'"
      class="disable-events"
    >
      <strong> {{ status }} </strong>
    </v-btn>
  </span>
</template>

<script>
export default {
  props: {
    status: String,
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  color: #ffffffa8 !important;
}
</style>
